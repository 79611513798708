import { HcLazyload } from '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component'

export default class BlockCustomerMeDeplacer {
    constructor() {
        BlockCustomerMeDeplacer.navigation()
    }

    static navigation() {
        if ($('[data-element="block-me-deplacer-navigation"]').length > 0) {
            $('.block__customer__me_deplacer__section__level1').on('click', function () {
                $('.block__customer__me_deplacer__section').removeClass('active')
                $(this).parent().addClass('active')
                HcLazyload.update()
            })
        }
    }
}

new BlockCustomerMeDeplacer()
